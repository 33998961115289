<template>
  <b10-legend
    :showing.sync="internalShowing"
  >
    <template
      slot="legend"
    >
      <v-simple-table>
        <template #default>
          <tbody>
            <tr>
              <td>
                <v-chip
                  small
                  label
                  dark
                  :color="PRESUPUESTOCLI.colores.pendiente"
                >
                  {{ PRESUPUESTOCLI.descripciones.pendienteRevisionComercial }}
                </v-chip>
              </td>
              <td>
                Presupuesto en estado "{{ PRESUPUESTOCLI.descripciones.pendienteRevisionComercial }}"
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  small
                  label
                  dark
                  :color="PRESUPUESTOCLI.colores.aceptado"
                >
                  {{ PRESUPUESTOCLI.descripciones.aceptado }}
                </v-chip>
              </td>
              <td>
                Presupuesto en estado "{{ PRESUPUESTOCLI.descripciones.aceptado }}"
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  small
                  label
                  dark
                  :color="PRESUPUESTOCLI.colores.rechazado"
                >
                  {{ PRESUPUESTOCLI.descripciones.rechazado }}
                </v-chip>
              </td>
              <td>
                Presupuesto en estado "{{ PRESUPUESTOCLI.descripciones.rechazado }}"
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  small
                  label
                  dark
                  color="info"
                >
                  185,19 €
                </v-chip>
              </td>
              <td>
                Importe de artículos y servicios
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  small
                  label
                  dark
                  color="info"
                >
                  40,00 € (cuotas)
                </v-chip>
              </td>
              <td>
                Importe de cuotas
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </b10-legend>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import { PRESUPUESTOCLI } from '@/utils/consts'

export default {
  mixins: [internalShowingMixin],
  data () {
    return {
      PRESUPUESTOCLI,
    }
  }
}
</script>
